<template>
  <div id="question" class="wrapper">
    <main class="main">
      <div v-if="data" class="container">
        <div class="question">
          <nav class="btn-back" @click.stop="back()">
            <a href="#"><span class="sp-none">もどる</span></a>
          </nav>
          <nav
            :class="[
              'btn-auto-read',
              doSpeech ? 'active' : null,
              canSpeech ? null : 'disabled'
            ]"
            @click="toggleSpeech"
          >
            <a href="#">
              <span
                ><ruby><rb>読</rb><rt>よ</rt></ruby
                >み<ruby><rb>上</rb><rt>あ</rt></ruby
                >げ</span
              ></a
            >
          </nav>

          <div class="question-body">
            <h1 class="title"><span v-html="title"></span></h1>
            <div class="question-main">
              <div class="question-side">
                <div class="timer">{{ mm }}:{{ ss }}</div>
              </div>
              <div class="text" v-html="data.text"></div>
            </div>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <div
              v-for="(qestion, q) in data.smallQuestions"
              :key="'q' + q"
              class="answer"
            >
              <p class="q-text" v-html="qestion.text"></p>
              <p class="q-prefix" v-html="qestion.prefix"></p>

              <ValidationText
                :id="'vt' + q"
                v-model="results[q]"
                type="text"
                :checkpoints="qestion.checkpoints"
              />

              <p class="q-suffix" v-html="qestion.suffix"></p>
            </div>
            <nav>
              <a href="https://www.google.com" target="_blank"
                ><span
                  ><ruby><rb>検索</rb><rt>けんさく</rt></ruby></span
                ></a
              >
              <a href="#" @click.prevent="validate">
                <span
                  ><ruby><rb>解答</rb><rt>かいとう</rt>する</ruby></span
                ></a
              >
              <a href="#" @click.prevent="show_hint"> <span>ヒント</span></a>
            </nav>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  formatAll,
  //formatAllasLine,
  formatAsSpeechText
} from '@/service/formatter.js';
import Dialog from '@/components/Dialog.vue';
import dialogs from '../../mixins/dialogs';
import back from '@/mixins/back';
import { zeropad2 } from '@/service/utils';
import { controlCheckpoints } from '@/service/checkpoints';
import { formatAllasLine } from '../../service/formatter';
const FPS = 2;
export default {
  name: 'Question',
  components: {
    ValidationText: () => import('@/components/ValidationText.vue')
  },
  mixins: [dialogs, back],
  props: {
    title: String,
    id: String,
    no: Number,
    length: Number,
    data: Object
  },
  data() {
    return {
      current: 0,
      timer: null,
      start: 0,
      time: 0,
      mm: '00',
      ss: '00',
      input: '',
      results: [],
      canSpeech: false,
      doSpeech: false,
      speech: null
    };
  },
  mounted() {
    if (this.data === undefined) {
      this.$router.push({ name: 'AbilityMenu' }).catch(() => {});
      return;
    }
    if (this.data) {
      this.data.text = formatAll.format(this.data.text);
      this.data.comment = formatAll.format(this.data.comment);
      this.data.speech_text = formatAsSpeechText.format(this.data.text);
      this.data.speech_comment = formatAsSpeechText.format(this.data.comment);
      for (let question of this.data.smallQuestions) {
        question.checkpoints = controlCheckpoints[question.control];
        question.prefix = formatAllasLine.format(question.prefix);
        question.suffix = formatAllasLine.format(question.suffix);
        question.text = formatAllasLine.format(question.text);
        this.results.push('');
      }
      this.canSpeech = window.speechSynthesis;
      if (this.canSpeech) {
        this.speech = new SpeechSynthesisUtterance();
        this.speech.lang = 'ja-JP';
        this.speech.rate = 1.0;
        this.speech.pitch = 1.5;
        this.speech.volume = 1.0;
      }
    }
    this.start = new Date().getTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000 / FPS);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    updateTime() {
      this.time = (new Date().getTime() - this.start) / 1000;
      const mm = Math.floor(this.time / 60);
      this.mm = zeropad2(mm);
      this.ss = zeropad2(Math.floor(this.time) - mm * 60);
    },
    async validate() {
      console.log('validate');
      let ok = true;
      for (let q in this.data.smallQuestions) {
        const qestion = this.data.smallQuestions[q];
        const answer = this.results[q];
        if (
          !qestion.answers.find(item => {
            return item === answer;
          })
        ) {
          ok = false;
          break;
        }
      }
      console.log('ok', ok);
      if (ok) {
        this.$tryLoadingAsync(async () => {
          this.updateTime();
          const payload = {
            id: this.id,
            no: this.no,
            time: this.time
          };
          const mm = this.mm;
          const ss = this.ss;
          console.log('setFacilityUserData', payload);
          const result = await this.$store.dispatch(
            'api/setFacilityUserData',
            payload
          );
          console.log('setFacilityUserData', result);
          this.$router
            .push({
              name: 'Result',
              params: {
                title: this.title,
                id: this.id,
                no: this.no,
                length: this.length,
                data: this.data,
                mm: mm,
                ss: ss
              }
            })
            .catch(() => {});
        });
      } else {
        await this.$confirm({
          component: Dialog,
          props: {
            message: 'まちがい'
          }
        });
      }
    },
    async show_hint() {
      await this.$confirm({
        component: Dialog,
        props: {
          title: 'ヒント',
          message: this.data.hint
        }
      });
    },
    exec_speech() {
      if (this.doSpeech && this.canSpeech) {
        this.speech.text = this.data.speech_text;
        speechSynthesis.speak(this.speech);
      }
    },
    toggleSpeech() {
      this.doSpeech = !this.doSpeech;
      this.exec_speech();
    }
  }
};
</script>

<style>
@media only screen and (min-width: 1001px) {
  #question .main .container .question .question-body .answer .validation-text {
    font-size: 2.8rem;
    height: 60px;
    border-radius: 15px;
    border-width: 2px;
    width: 340px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  #question .main .container .question .question-body .answer .validation-text {
    font-size: 1.8rem;
    height: 40px;
    border-radius: 10px;
    border-width: 2px;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
